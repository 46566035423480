// Copyright 2015-2021 Swim.inc
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/** @internal */
export class ColorChannel {
  constructor(value: number, units?: string) {
    this.value = value;
    this.units = units !== void 0 ? units : "";
  }

  readonly value: number;

  readonly units: string;

  scale(k: number): number {
    if (this.units === "%") {
      return this.value * k / 100;
    } else {
      return this.value;
    }
  }
}
