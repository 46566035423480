// Copyright 2015-2021 Swim.inc
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/** @public */
export interface CssContext {
  /** @internal */
  getRule(index: number): CSSRule | null;

  /** @internal */
  insertRule(cssText: string, index?: number): number;

  /** @internal */
  removeRule(index: number): void;
}

/** @public */
export const CssContext = (function () {
  const CssContext = {} as {
    is(object: unknown): object is CssContext;
  };

  CssContext.is = function (object: unknown): object is CssContext {
    if (typeof object === "object" && object !== null || typeof object === "function") {
      const cssContext = object as CssContext;
      return "getRule" in cssContext;
    }
    return false;
  };

  return CssContext;
})();
